
import { Vue, Component } from 'vue-property-decorator'
import { AMSAccountAPI, ApiTokenAPI } from '@/api'
import { PageHeader } from '@/components/page-header'
import { Card } from '@/components/card'

@Component({
  components: {
    PageHeader,
    Card,
  },
})
export default class ApiToken extends Vue {
  loading = true
  isLoggedIn = false
  isTokenAlreadyLinked = false
  reasonOfUse = ''
  apiToken: string | null = null
  errorMessage: string | null = null
  testing = ''

  //
  // LIFECYCLE HOOKS
  //
  async mounted() {
    this.testing = this.$route.query.testing !== undefined ? this.$route.query.testing : ''

    if (this.testing.startsWith('token')) {
      this.apiToken = this.testing
    }

    await this.checkIfIsLoggedIn()
    await this.checkIfUserHasToken()
    this.loading = !this.isLoggedIn
  }

  //
  // COMPUTED PROPERTIES
  //

  get apiDocsURL() {
    return this.$store.state.AppConfigModule.appConfig.restAPIDocsURL
  }

  //
  // METHODS
  //
  async checkIfIsLoggedIn() {
    if (this.testing !== '') {
      this.isLoggedIn = true
      return
    }

    this.isLoggedIn = await AMSAccountAPI.isLoggedIn()

    if (!this.isLoggedIn) {
      AMSAccountAPI.redirectToAMSLogin(window.location.href)
    }
  }

  async checkIfUserHasToken() {
    if (this.isLoggedIn) {
      const response = await ApiTokenAPI.getUserInfo()

      if (this.testing === 'no-token' || this.testing.startsWith('token')) return
      this.isTokenAlreadyLinked = response && response.hasToken === true
    }
  }

  async onSubmit(e: Event) {
    e.preventDefault()
    const response = await ApiTokenAPI.generateToken(this.reasonOfUse)

    if (response.token) {
      this.apiToken = response.token
    } else if (response.errorMessage) {
      this.errorMessage = response.errorMessage
    } else {
      this.errorMessage = 'Something went wrong generatin the API token.'
    }
  }

  copyToClipboard() {
    const copyToClipboardUsingAPI = str => {
      if (navigator && navigator.clipboard && navigator.clipboard.writeText) { return navigator.clipboard.writeText(str) }
      return Promise.reject(Error('The Clipboard API is not available.'))
    }

    const copyToClipboardUsingCommand = str => {
      if (document.getSelection() !== null) {
        const el = document.createElement('textarea')
        el.value = str
        el.setAttribute('readonly', '')
        el.style.position = 'absolute'
        el.style.left = '-9999px'
        document.body.appendChild(el)
        const selected =
          (document.getSelection() as Selection).rangeCount > 0
            ? (document.getSelection() as Selection).getRangeAt(0)
            : false
        el.select()
        document.execCommand('copy')
        document.body.removeChild(el)
        if (selected) {
          (document.getSelection() as Selection).removeAllRanges();
          (document.getSelection() as Selection).addRange(selected)
        }
      }
    }

    copyToClipboardUsingAPI(this.apiToken).catch(err => {
      console.error(err)
      console.info('Using a different method to copy text.')
      copyToClipboardUsingCommand(this.apiToken)
    })
  }
}
